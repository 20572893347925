<template>
      <v-app class>
        <div>
          <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
              {{alertMessage}}
          </v-alert>
        </div>

        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <v-container>
              <div class="mb-4 mt-8 d-flex ">
                <div class="col-md-11">
                  <h1>PPE Requests</h1>
                </div>
                <div class="col-md-1">
                  <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2" />
                </div>

              </div>
              <div class="col-md-12 ml-auto mr-auto">
                <v-text-field
                  v-model="searchPersonnel"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
              <v-data-table
                :headers='headers'
                :items="ppes"
                :loading="loadingLogs"
                :search="searchPersonnel"
              >
                <template v-slot:item.request.dateAdded="{ item }">
                  {{item.request.dateAdded | formatDate}}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <Button :btnType="'Submit'" :color="'info'" :label="'View'" @onClick="view(item)" class="mr-2" />
                     <Button v-if="item.status !== 'Approved'" :btnType="'Submit'" :color="'primary'" :label="'Forward'" @onClick="openPersonnelSearch(item)" class="mr-2"/>
                    <!-- <Button :btnType="'Cancel'" :label="'Delete'" @onClick="openDeleteModal(item)" /> -->
                  </div>
                </template>

              </v-data-table>
              <!-- View modal  -->
              <Dialog ref="ppeViewModal" :title="'PPE'" :width="700">
                <v-row v-if="ppeRequest !== null">
                  <p><span class="text-caption">{{ppeRequest.employeeName}}</span></p>
                  <v-simple-table style="width: 100%">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>PPE Type</th>
                          <th>Quantity</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in ppeItems" :key="item.requestId">
                          <td><p>{{item.ppeType.name}}</p></td>
                          <td><p>{{item.narration}}</p></td>
                          <td>
                            <p v-if="item.request.status == 0">Pending </p>
                            <p v-if="item.request.status == 1">Approved </p>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-card width="700" elevation="0">
                    <v-tabs v-model="tab">
                    <v-tab href="#tab-1" class="tab-title">Approver</v-tab>
                    <v-tab href="#tab-2">Log</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                      <v-card class="pt-2" flat>
                        <div v-if="loadingLogs" class="text-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </div>
                        <v-col v-else class="pt-3 pr-1 pl-1" :md="12" v-for="(approver, index) in approvers" :key="index">
                          <p class="text-left mb-1 approval-title">Waiting on ({{approver.approver}}): {{approver.approvers[0]}}</p>
                    <!--    <v-select
                          :items="approver.approvers"
                          outlined
                          dense
                          :value="approver.approvers[0]"
                        ></v-select> -->
                      </v-col>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                      <v-card class="pt-2" flat>
                        <div v-if="loadingLogs" class="text-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </div>
                        <v-data-table v-else
                                      :headers='logHeaders'
                                      :items="logs">
                          <template v-slot:item.activityDate="{ item }">
                            <span>{{ item.activityDate | formatDate }}</span>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  </v-card>
                </v-row>
                <template v-slot:footer>
                  <!-- <Button :btnType="'Close'" :label="'Cancel'" @onClick="closeallowanceViewModal" /> -->
                </template>
              </Dialog>
              <!-- View modal  -->
            </v-container>
          </div>
        </div>
         <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)" @blur="personnelComment ($event)" @close="personnelSearch = false" :type="'employee'" :title="'Personnel'">
       <Button
        :label="'Forward'"
        :btnType="'Submit'"
        @onClick="forwardRequest"
        :disabled="DelegateEmail === null"
        :isLoading="forwarding"
        class="mr-4"
      />
     </PersonnelSearch>
      </v-app>

</template>
<script>

import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { FETCH_PPE, FORWARD_REQUEST } from '@/store/action-type'
import { requestService } from '@/services'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
export default {
  components: {
    Button,
    Dialog,
    PersonnelSearch
  },
  data () {
    return {
      search: '',
      formData: {
        name: '',
        id: ''
      },
      searchPersonnel: '',
      tab: null,
      valid: false,
      isSaving: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      personnelSearch: false,     
      DelegateName: null,
      DelegateEmail: null,
      loadingLogs: false,
      forwarding: false,
      ppeRequest: null,
      approvers: [],
      ppeItems: [],
      logs: [],
      headers: [
        {
          text: 'Personnel Name',
          value: 'employeeName',
          width: '%'
        },
        {
          text: 'No. of Request',
          value: 'requestCount'
        },
        {
          text: 'Location',
          value: 'location'
        },
        {
          text: 'Job Title',
          value: 'jobTitle'
        },
        {
          text: 'Employer',
          value: 'contractEmployerName'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Date Added',
          value: 'requestDate',
          width: '10%'
        },
        {
          text: 'Modified Date',
          value: 'modifiedDate'
        },
        {
          text: 'Modified By',
          value: 'modifiedBy'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      logHeaders: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Action by',
          value: 'username'
        },
        {
          text: 'Comment',
          value: 'comments',
          width: '200px'
        },
        {
          text: 'Date',
          value: 'activityDate',
          width: '150px'
        }
      ]
    }
  },
  computed: {
    ppes () {
      // console.clear()
      // console.log(this.$store.getters.ppes)
      return this.$store.getters.ppes
    },
    currentUser () {
      // console.clear()
      // console.log(this.$store.getters.ppes)
      return this.$store.getters.users
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    Add () {
      this.$router.push('/ppe-request/add')
    },
    getUserRole (role) {
      if (this.currentUser.roles.includes(role)) {
        return true
      } else {
        return false
      }
    },
    view (item) {
      console.log(item)
      this.ppeItems = item.ppEs
      this.ppeRequest = item
      this.loadingLogs = true
      requestService.ppeApprovalLogs(item.ppEs[0].requestId).then(({ data }) => {
        this.approvers = data.currenApprover
        this.logs = data.logs
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.loadingLogs = false
      })
      this.$refs.ppeViewModal.openModal()
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name     
    },
    forwardRequest () {
      this.forwarding = true
      let data = {
        ItemGuid: this.item.requestGuid,
        // "ItemId":428,//This should be the Employee Id       
        'DelegateName': this.DelegateName,
        'DelegateEmail': this.DelegateEmail
      }

      this.$store.dispatch(FORWARD_REQUEST, { data, type: 'PPE' }).then((res) => {
        this.showAlertMessage('Forward request successful', 'success')
      }).catch(() => {
        this.showAlertMessage('Failed to Forward try again', 'error')
      }).finally(() => {
        this.personnelSearch = false
        this.forwarding = false        
        this.DelegateName = null
        this.DelegateEmail = null
      })

      console.log('forward data', data)
    },
    getPpe () {
      this.loadingLogs = true
      this.$store
        .dispatch(FETCH_PPE)
        .then(() => {
          this.loadingLogs = false
        })
        .catch((error) => {
          if (error) {
            this.loadingLogs = false

          // this.showAlertMessage('There are no items to display', 'warning')
          }
        })
    }
  },
  mounted () {
    this.getPpe()
    // this.$store
    //   .dispatch(FETCH_PPE)
    //   .then(() => {})
    //   .catch((error) => {
    //     if (error) {
    //       this.showAlertMessage('There are no items to display', 'warning')
    //     }
    //   })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.iconAvatar {
  width: 122px;
}
.text-caption {
  font-weight: 600;
  font-size: 14px !important
}
.approval-title{
      font-size: 14px;
    font-weight: bold;
    margin-bottom: 40px;
}
.v-tab--active{
   background-color: #faf8f8 !important;
   color: #0b2d71 !important
}
.v-tabs-slider-wrapper{
  background-color: #0b2d71 !important
}
</style>
